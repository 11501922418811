import React from 'react';

import Link from 'next/link';

import UseSlugRoute from 'hooks/use-slugroute';

import Badge from '@core/components/badge/Badge';
import ContentPrice from 'components/common/contentprice/ContentPrice';

import { Lacking, Updated } from 'constants/content-status';

import StarRating from 'assets/icons/star-rating.svg';
import ArrowRight from 'assets/icons/arrow-right.svg';
import ExistWishlist from 'assets/icons/wishlist-card-icon.svg';
import VIPIcon from 'assets/icons/vip-card-icon.svg';

import classes from './LectureCard.module.scss';

const LectureCard = ({
	lecture: {
		id,
		title,
		image,
		rate_average,
		exist_in_wishlist,
		participants_count,
		price,
		payable_price,
		promotion_percentage,
		status,
		has_been_updated,
		has_vip_access,
		slug,
		is_free,
		discounted_price,
		currency
	},
	className
}) => {
	const slugRoute = UseSlugRoute();

	return (
		<Link
			test-id="lecture-card"
			href={slugRoute(`/courses/${id}`, slug)}
			className={`${classes['lecture-card-wrapper']} ${className ? classes[className] : ''}`}>
			<div className={`${classes['lecture-card-top']} d-flex flex-row `}>
				<img src={image} alt={title} />
				<div className="d-flex flex-column justify-content-center justify-content-md-between w-100">
					<div className="d-flex flex-column">
						<span className="d-flex flex-row align-items-center gap-1">
							{has_been_updated && <Badge status={Updated} />}
							<Badge status={status} />
						</span>
						<div className={classes['title']}>
							<h4>{title}</h4>
							{has_vip_access && <VIPIcon />}
						</div>
						<div className="d-flex flex-row align-items-center">
							<span className="d-flex flex-row align-items-center m-0">
								{exist_in_wishlist && (
									<div className="d-flex flex-row align-items-center">
										<ExistWishlist />
										<span className={classes['dot']} />
									</div>
								)}
								<div className="d-flex flex-row align-items-center gap-2">
									<StarRating /> {rate_average}
								</div>
							</span>
							<span className={`${classes['dot']} m-0`} />
							<p>{participants_count} participants</p>
						</div>
					</div>
					<div
						className={`flex-row align-items-center justify-content-between ${className === 'home-page' ? 'd-none' : 'd-none d-md-flex'
							}`}>
						<span className={classes['lecture-details']}>
							Details <ArrowRight />
						</span>
						{status === Lacking || has_vip_access ? (
							<ContentPrice
								price={price}
								payable_price={payable_price || (has_vip_access ? 0 : null)}
								promotion_percentage={promotion_percentage}
								is_free={is_free}
								discounted_price={discounted_price}
								currency={currency}
							/>
						) : (
							''
						)}
					</div>
				</div>
			</div>
			<div
				className={` flex-row align-items-center justify-content-between gap-2 ${className === 'home-page' ? 'd-flex' : 'd-flex d-md-none'
					}`}>
				<span className={classes['lecture-details']}>
					Details <ArrowRight />
				</span>
				{status === Lacking || has_vip_access ? (
					<ContentPrice
						price={price}
						payable_price={payable_price || (has_vip_access ? 0 : null)}
						promotion_percentage={promotion_percentage}
						is_free={is_free}
						discounted_price={discounted_price}
						currency={currency}
					/>
				) : (
					''
				)}
			</div>
		</Link>
	);
};

export default LectureCard;
