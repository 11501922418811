import React from 'react';

import { useRouter } from 'next/router';
import useWindowSize from 'hooks/use-windowSize';

import { useQuery } from 'react-query';
import { CategoriesService } from 'services/core';

import Spinner from '@core/components/spinner/Spinner';

import classes from './SearchPageCategories.module.scss';

const SearchPageCategories = ({ categoryHandler, categories = '' }) => {
	const { desktopDevice } = useWindowSize();
	const router = useRouter();

	const { pathname } = router;
	const { id, filters } = router.query;
	const { data, isLoading } = useQuery(['searchpage-categories', filters], () => CategoriesService(filters));
	const selectedCategories = categories.split(',')?.map((id) => Number(id));
	return (
		<div className={`${classes['search-page-categories']}`}>
			<h2>Filter by category:</h2>
			{data && !isLoading ? (
				<>
					{data.data?.results?.map((category) => (
						<button
							onClick={() => categoryHandler(category.id)}
							className={`${classes['category-card']} ${
								selectedCategories.includes(category.id) ||
								(/category/.test(pathname) && +id === category.id && desktopDevice)
									? classes['active']
									: ''
							} d-flex flex-row`}
							key={category.id + category.name}>
							<img
								src={category.image}
								alt={category.name}
								style={{ backgroundColor: category.color + '20' }}
							/>
							<div>
								<h3>{category.name}</h3>
								<h4 className="d-flex flex-row align-items-center">
									{category.course_count} job qualifications <span /> {category.lecture_count}{' '}
									courses
								</h4>
							</div>
						</button>
					))}
				</>
			) : (
				<div
					className={`${classes['category-loading']} d-flex flex-row align-items-center justify-content-center`}>
					<Spinner />
				</div>
			)}
		</div>
	);
};

export default React.memo(SearchPageCategories);
