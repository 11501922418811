import React from 'react';

import Link from 'next/link';

import Badge from '@core/components/badge/Badge';
import ContentPrice from 'components/common/contentprice/ContentPrice';

import UseSlugRoute from 'hooks/use-slugroute';

import { Lacking, Updated } from 'constants/content-status';

import StarRating from 'assets/icons/star-rating.svg';
import ArrowRight from 'assets/icons/arrow-right.svg';
import ExistWishlist from 'assets/icons/wishlist-card-icon.svg';
import VIPIcon from 'assets/icons/vip-card-icon.svg';

import classes from './CourseCard.module.scss';

const CourseCard = ({
	course: {
		id,
		title,
		image,
		rate_average,
		lecture_count,
		exist_in_wishlist,
		participants_count,
		price,
		payable_price,
		promotion_percentage,
		status,
		has_been_updated,
		has_vip_access,
		slug,
		discounted_price,
		currency,
	},
	className
}) => {
	const slugRoute = UseSlugRoute();
	return (
		<Link
			href={slugRoute(`/job-qualifications/${id}`, slug)}
			test-id="course-card"
			className={`${classes['course-card-wrapper']} d-flex flex-column
			${className ? classes[className] : ''} ${className === 'home-page' ? 'flex-xl-row' : 'flex-md-row'
				} `}>
			<img src={image} alt={title} />
			<div className="d-flex flex-column justify-content-between w-100">
				<div className={`${classes['course-card-right-top']} d-flex flex-column`}>
					<span className="d-flex flex-row align-items-center gap-1">
						{has_been_updated && <Badge status={Updated} />}
						<Badge status={status} />
					</span>
					<div className={classes['title']}>
						<h4>{title}</h4>
						{has_vip_access && <VIPIcon />}
					</div>
					<div
						className={`d-flex flex-row-reverse justify-content-end align-items-center align-items-md-start ${className === 'home-page' ? 'flex-xl-column' : 'flex-md-column'
							}`}>
						<p className="d-flex">
							{participants_count} participants <span className={classes['dot']} /> {lecture_count}{' '}
							courses
						</p>
						<span className="d-flex flex-row align-items-center">
							{exist_in_wishlist && (
								<>
									<ExistWishlist />
									<span className={classes['dot']} />
								</>
							)}
							<div className="d-flex flex-row align-items-center gap-2">
								<StarRating /> {rate_average}
							</div>
						</span>
					</div>
				</div>
				<div className="d-flex flex-row align-items-center justify-content-between gap-2">
					<span className={classes['course-details-side']}>
						Details <ArrowRight />
					</span>
					{status === Lacking || has_vip_access ? (
						<ContentPrice
							price={price}
							payable_price={payable_price || (has_vip_access ? 0 : null)}
							promotion_percentage={promotion_percentage}
							discounted_price={discounted_price}
							currency={currency}
						/>
					) : (
						''
					)}
				</div>
			</div>
		</Link>
	);
};

export default CourseCard;
