import React, { useState } from 'react';

import classes from './ContentPrice.module.scss';

const ContentPrice = ({ price, payable_price, promotion_percentage, className, is_free, discounted_price, currency }) => {
	const [payablePrice] = useState(() =>
		payable_price !== undefined && payable_price !== null
			? payable_price
			: discounted_price
	);
	const [symbol] = useState(() =>
		currency !== undefined && currency.symbol !== undefined && currency.symbol !== null
			? currency.symbol
			: '€'
	);
	return (
		<>
			<div
				className={`${classes['cart-item-price']} d-flex flex-row align-items-center gap-2 
				${className || ''}`}>
				{!is_free ? (
					<>
						{/* <h2
								className={`${classes[
									payablePrice !== undefined && payablePrice !== null && payablePrice !== price
										? 'strike'
										: ''
								]
									}`}>
								<span>{symbol}</span>{price}
							</h2>
							{payablePrice !== undefined && payablePrice !== null && payablePrice !== price && (
								<h2><span>{symbol}</span>{payablePrice}</h2>
							)}
							{promotion_percentage && (
								<h2 className={`${classes['promotion-percent']}`}>{promotion_percentage}%</h2>
							)} */}
					</>
				) : (
					<h2>Free</h2>
				)}
			</div>
		</>

	);
};

export default ContentPrice;
