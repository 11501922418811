export const generateSeoProps = ({
	title = 'Pharmuni',
	description = 'Welcome to the world of Pharma',
	canonical = '',
	noIndex = true
}) => {
	return {
		title: title,
		description: description,
		canonical: 'https://www.pharmuni.com/' + canonical,
		openGraph: {
			type: 'website',
			locale: 'en_US',
			url: 'https://www.pharmuni.com/' + canonical,
			site_name: 'Pharmuni',
			title: title,
			description: description,
			noIndex,
			// images: [
			// 	{
			// 		url: 'https://pharmuni.com/wp-content/uploads/2022/10/PHU_Logo-1-1.svg',
			// 		alt: 'Pharmuni'
			// 	}
			// ]
		},
		additionalMetaTags: [
			{
				name: 'viewport',
				content: 'width=device-width, initial-scale=1.0'
			},
			{
				property: 'og:type',
				content: 'website'
			}
		]
	};
};
